import React from "react";
import { GitHub, LinkedIn, Email } from "./Logos";
import SlideIn from "../universal/SlideIn";

export default class SocialMedia extends React.Component {
  render() {
    return (
      <div>
        <SlideIn
          isSegmentInViewport={true}
          isAnimationSlideIn={false}
          animationDelayClass="delay-150"
        >
          <div className="pa4-l pa3 fixed-l left-0-l bottom-0-l flex flex-column-l flex-row flex-nowrap justify-center align-center">
          {/* <div className="justify-center align-center"> */}
            <GitHub />
            <LinkedIn />
            <Email />
          </div>
        </SlideIn>
      </div>
      
    );
  }
}
